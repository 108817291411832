
import { BSwitch } from 'buetify/lib/components';
import { defineComponent, shallowRef } from 'vue';

export default defineComponent({
	name: 'switch-simple-example',
	components: {
		BSwitch
	},
	setup() {
		return {
			isSwitched: shallowRef(false),
			isSwitchedCustom: shallowRef('Yes')
		};
	}
});
